
  import { Component, Vue } from 'vue-property-decorator'
  import QuizReview from '../../api/quizReview'
  import getApi from '@/api'
  import { UserInfoInterface } from '@/interface/BaseInterface'
  import { size, map, unionBy, differenceBy, includes, filter, kebabCase } from 'lodash'

  const quizReview: QuizReview = getApi('quizReview')

  @Component({ components: {} })
  export default class QuizAssign extends Vue {
    teachers: UserInfoInterface[] = []
    freeStudents: UserInfoInterface[] = []

    assignStudents: UserInfoInterface[] = []

    checkedStudents: string[] = []
    checkedAssignStudents: string[] = []

    teacherSelect = ''
    search = ''

    isAssignLoading = false
    isLoading = false
    checkAll = false

    isIndeterminate = false
    isTeacherLoading = false
    isRemove = false

    get getUsers() {
      if (this.search === '') {
        return this.freeStudents
      }
      return filter(this.freeStudents, value => {
        const name = value.firstName + ' ' + value.lastName
        return includes(kebabCase(name), kebabCase(this.search))
      })
    }

    async init() {
      try {
        this.isLoading = true
        const result = await Promise.all([quizReview.listTeachers(this.courseId), quizReview.listMembersFree(this.courseId)])
        this.teachers = result[0] || []
        this.freeStudents = result[1] || []

        if (size(this.teachers) > 0) {
          this.teacherSelect = this.teachers[0]._id
          const result1 = await quizReview.listAssignStudents(this.courseId, this.teacherSelect)
          this.assignStudents = result1 || []
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    async handleAssigns() {
      try {
        if (size(this.checkedStudents) === 0) {
          this.$message.error(`No one is selected!`)
          return
        }
        const result = await quizReview.assignStudents(this.courseId, this.teacherSelect, this.checkedStudents)
        this.assignStudents = unionBy(this.assignStudents, result, '_id')
        this.freeStudents = differenceBy(this.freeStudents, result, '_id')
        this.checkedStudents = []
        this.checkedAssignStudents = []
        this.checkAll = false
        this.$notify.success(this.$t('success') as string)
      } catch (error) {
        console.log(error)
      }
      console.log('assign')
    }

    handleCheckAllChange(val: boolean) {
      this.checkedStudents = val ? map(this.freeStudents, value => value._id) : []
      this.isIndeterminate = false
    }

    handleCheckedStudentsChange(value: string[]) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.freeStudents.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.freeStudents.length
    }

    handleCheckedAssignStudentsChange(value: string[]) {
      // const checkedCount = value.length
      // this.checkAll = checkedCount === this.freeStudents.length
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.freeStudents.length
      console.log(value)
    }

    async handleTeacherChange(value: string) {
      console.log('teacher chagne', value)
      try {
        this.isTeacherLoading = true
        this.assignStudents = []
        const result1 = await quizReview.listAssignStudents(this.courseId, this.teacherSelect)
        this.assignStudents = result1 || []
        this.isTeacherLoading = false
      } catch (error) {
        this.assignStudents = []
        this.isTeacherLoading = false
      }
    }

    async handeRemoveStudents() {
      try {
        if (size(this.checkedAssignStudents) === 0) {
          this.$message.error(`No one is selected!`)
          return
        }
        this.isRemove = true
        await quizReview.removeStudents(this.courseId, this.teacherSelect, this.checkedAssignStudents)
        const removeStudents = filter(this.assignStudents, value => includes(this.checkedAssignStudents, value._id))
        this.freeStudents = unionBy(this.freeStudents, removeStudents, '_id')
        this.assignStudents = differenceBy(this.assignStudents, removeStudents, '_id')
        this.checkedStudents = []
        this.checkedAssignStudents = []
        this.checkAll = false
        this.isRemove = false
        this.$notify.success(this.$t('success') as string)
      } catch (error) {
        this.isRemove = false
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
